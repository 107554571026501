<template>
	<main class="single">
		<div class="background">
			<section
				v-if="domain"
				class="whoisContact form"
			>
				<h2>{{ i18n.t('Domain Owner Contact Form') }}</h2>
				<div class="domainName">
					{{ domain }}
				</div>
				<p>{{ i18n.t('Please enter your information and the message that you want to send to the owner of the domain name listed above.') }}</p>
				<form
					autocomplete="off"
					@submit="handleSubmit"
				>
					<InputGroup
						ref="form"
						:config="config"
					/>
					<div
						:id="recaptchaId"
						class="g-recaptcha"
					/>
					<p class="disclaimer">
						<span class="bold">{{ i18n.t('All of the data provided above will be sent to the domain owner and a copy will be sent to you at the email address you provided.') }}</span>
						{{ i18n.t(`${registrar} will keep a log of the email address provided on this form, along with the date and time of the request, for two years, for auditing purposes. We will not keep a copy of the message after it has been forwarded to the domain owner. `) }}
						<template v-if="dpo">
							{{ i18n.t(`The ${registrar} Data Protection Officer can be reached at ${dpo}.`) }}
						</template>
						{{ i18n.t(`You have the right to lodge a complaint with a supervisory authority.`) }}
					</p>
					<FooterField
						:submit="{label: i18n.t('Send Message')}"
						:cancel="false"
						@submit="handleSubmit"
					/>
				</form>
			</section>
		</div>
	</main>
</template>

<script>
/**
 * @class Contact
 *
 * Contact form use to send an email to the domain owner which is hidden from public.
 * */
import InputGroup from 'tucows-ui-components/src/Input/InputGroup.vue';
import FooterField from 'tucows-ui-components/src/FooterField.vue';
import {mapActions, mapGetters, mapState} from 'vuex';
import i18n from '@/lib/i18n';
import _get from 'lodash/get';

export default {
	name: 'WhoisContact',
	components: {
		FooterField,
		InputGroup
	},
	/**
	 * @ignore
	 */
	data() {
		return {
			recaptchaId: 'whoisContactRecaptcha',
			recaptchaWidgetId: null,
			config: [
				{
					label: i18n.t('First Name'),
					name: 'first_name',
					required: true,
					value: '',
				},
				{
					label: i18n.t('Last Name'),
					name: 'last_name',
					required: true,
					value: '',
				},
				{
					label: i18n.t('Email Address'),
					name: 'sender_email',
					compType: 'email',
					required: true,
					value: '',
				},
				{
					label: i18n.t('Message Subject Line'),
					name: 'subject',
					required: true,
					value: '',
				},
				{
					label: i18n.t('Message Body'),
					name: 'body',
					compType: 'textarea',
					required: true,
					value: '',
					maxlength: 5000,
					minlength: 10
				}
			]
		};
	},
	computed: {
		...mapGetters({
			domain: 'whoisContact/domain',
			brand: 'whoisContact/brand',
			registrar: 'whoisContact/registrar',
			dpo: 'whoisContact/dpo',
			recaptchaSiteKey: 'appConfig/recaptchaKey'
		}),
		...mapState({errors: (state) => _get(state, 'whoisContact.errors')}),
		/**
			 * Token from url to get brand and domain
			 * @return {string}  Token id
			 */
		token() {
			return _get(this.$route, 'params.token');
		}
	},

	/**
	 * Get domain and brand from the token in contact url
	 * then initialize the recaptcha element
	 * @return {promise} resolve
	 */
	mounted() {
		return this.getFormValues();
	},
	methods: {
		...mapActions({
			showSuccess: 'common/showSuccess',
			getWhoisContact: 'whoisContact/get',
			sendWhoisContact: 'whoisContact/send'
		}),
		/**
		 * Get domain and brand from token id in url then set to form value
		 * @return {Promise}  Get domain and brand api call
		 */
		getFormValues() {
			return this.getWhoisContact({token: this.token})
				.then(() => {
					if (!this.errors) {
						// Wait for element before applying recaptcha
						this.$nextTick(() => this.renderRecaptcha());
					}
				});
		},
		/**
			 * Render recaptcha with the recaptchaId
			 */
		renderRecaptcha() {
			// If we need to get the response, then we need the widgetId
			this.recaptchaWidgetId = grecaptcha.render(this.recaptchaId, {'sitekey': this.recaptchaSiteKey});
		},
		/**
			 * Change password
			 */
		handleSubmit() {
			// If form is valid then submit and redirect to search
			if (this.$refs.form.isValid()) {
				const {
					body,
					first_name,
					last_name,
					sender_email,
					subject
				} = this.$refs.form.getData();

				return this.sendWhoisContact({
					brand: this.brand,
					token: this.token,
					form: {
						'domain': this.domain,
						'recaptcha': grecaptcha.getResponse(this.widgetId),
						'sender_name': `${first_name} ${last_name}`,
						body,
						subject,
						sender_email
					}
				})
					.then(() => {
						if (!this.errors) {
							this.$router.push('/contact/sent');
						} else {
							grecaptcha.reset();
						}
						return;
					});
			}
		}
	}
};
</script>

<style lang="less">
@import (reference) 'theme.less';

.whoisContact {

	.domainName {
		.bold;
		padding: 0 0  @padding-half 0;
		font-size: @f20px;
		text-align: center;
	}

	.disclaimer {
		padding: @padding-half 0 0 0;
		font-size: @f12px;
	}

	textarea {
		min-height: 10em;
	}
}

</style>
