<template>
	<main class="single">
		<div class="background">
			<section class="form">
				<h2>{{ i18n.t('Domain Owner Contact Form') }}</h2>
				<p>{{ i18n.t('Success! Your message has been sent to the domain owner and a copy has been sent to you.') }} </p>
			</section>
		</div>
	</main>
</template>

<script>
export default {name: 'WhoisContactSent'};
</script>
