var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", { staticClass: "single" }, [
    _c("div", { staticClass: "background" }, [
      _c("section", { staticClass: "form" }, [
        _c("h2", [_vm._v(_vm._s(_vm.i18n.t("Domain Owner Contact Form")))]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            _vm._s(
              _vm.i18n.t(
                "Success! Your message has been sent to the domain owner and a copy has been sent to you."
              )
            ) + " "
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }