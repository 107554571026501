import { render, staticRenderFns } from "./WhoisContact.vue?vue&type=template&id=204c835b"
import script from "./WhoisContact.vue?vue&type=script&lang=js"
export * from "./WhoisContact.vue?vue&type=script&lang=js"
import style0 from "./WhoisContact.vue?vue&type=style&index=0&id=204c835b&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tieredaccess/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('204c835b')) {
      api.createRecord('204c835b', component.options)
    } else {
      api.reload('204c835b', component.options)
    }
    module.hot.accept("./WhoisContact.vue?vue&type=template&id=204c835b", function () {
      api.rerender('204c835b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/WhoisContact/WhoisContact.vue"
export default component.exports