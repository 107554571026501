var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", { staticClass: "single" }, [
    _c("div", { staticClass: "background" }, [
      _vm.domain
        ? _c("section", { staticClass: "whoisContact form" }, [
            _c("h2", [_vm._v(_vm._s(_vm.i18n.t("Domain Owner Contact Form")))]),
            _vm._v(" "),
            _c("div", { staticClass: "domainName" }, [
              _vm._v("\n\t\t\t\t" + _vm._s(_vm.domain) + "\n\t\t\t"),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                _vm._s(
                  _vm.i18n.t(
                    "Please enter your information and the message that you want to send to the owner of the domain name listed above."
                  )
                )
              ),
            ]),
            _vm._v(" "),
            _c(
              "form",
              {
                attrs: { autocomplete: "off" },
                on: { submit: _vm.handleSubmit },
              },
              [
                _c("InputGroup", {
                  ref: "form",
                  attrs: { config: _vm.config },
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "g-recaptcha",
                  attrs: { id: _vm.recaptchaId },
                }),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "disclaimer" },
                  [
                    _c("span", { staticClass: "bold" }, [
                      _vm._v(
                        _vm._s(
                          _vm.i18n.t(
                            "All of the data provided above will be sent to the domain owner and a copy will be sent to you at the email address you provided."
                          )
                        )
                      ),
                    ]),
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm.i18n.t(
                            `${_vm.registrar} will keep a log of the email address provided on this form, along with the date and time of the request, for two years, for auditing purposes. We will not keep a copy of the message after it has been forwarded to the domain owner. `
                          )
                        ) +
                        "\n\t\t\t\t\t"
                    ),
                    _vm.dpo
                      ? [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(
                                _vm.i18n.t(
                                  `The ${_vm.registrar} Data Protection Officer can be reached at ${_vm.dpo}.`
                                )
                              ) +
                              "\n\t\t\t\t\t"
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm.i18n.t(
                            `You have the right to lodge a complaint with a supervisory authority.`
                          )
                        ) +
                        "\n\t\t\t\t"
                    ),
                  ],
                  2
                ),
                _vm._v(" "),
                _c("FooterField", {
                  attrs: {
                    submit: { label: _vm.i18n.t("Send Message") },
                    cancel: false,
                  },
                  on: { submit: _vm.handleSubmit },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }